<template>
    <hr style="height:2px;border: none; background-color: #fff;" />

    <div v-if="eventText != null" :class="`box opacity-07 ${trend.Direction === 'up' ? 'success-dialog' : 'error-dialog'}`">
        <span class="h4">Trend seit</span><br>
        <span class="h4">
            <img :src="imgPath" style="width:40px;height:40px;" /> <span v-html="eventText"></span>
        </span>
        <br>
        <!-- <span class="h3" style="margin-right:10px;">{{ trend.Timeframe.AvgScoreOnEventDt }}</span>
        <template v-if="trend.Direction == 'up'">
            <font-awesome-icon icon="arrow-trend-up" class="text-success black-shadow-box"
                style="border: 2px solid #3dcc5b;" />
        </template>
        <template v-else>
            <font-awesome-icon icon="arrow-trend-down" class="text-danger black-shadow-box"
                style="border: 2px solid #dc3545;" />
        </template>
        <span :class="directionClass + ' h3'"> &nbsp;{{ trend.AvgScore }}</span>
        <br>
        <b>{{ trend.Votes }} Stimmen</b>
        <span :class="directionClass + ''" style="margin-left:10px;">
            <template v-if="trend.Diff > 0">+</template><b>Differenz: {{ trend.Diff }}</b>
        </span>-->

        <span :class="directionClass + ' h3'" style="margin-left:10px;">
            <template v-if="trend.Diff > 0">+</template><b>{{ trend.Diff }}</b>
        </span>

    </div>
</template>

<script>
import * as utils from '../utils.js';
export default {
    props: {
        trend: {
            type: Object,
            required: true
        }
    },
    computed: {
        directionClass() {
            return this.trend.Direction === 'up' ? 'text-success' : 'text-danger';
        },
        imgPath() {
            return this.trend.Timeframe.EventDesc.type == 1
                ? "/chart-imgs/" + this.trend.Timeframe.EventDesc.image
                : "/chart-imgs/thunder.png";
        },
        eventText() {
            if(this.trend.Timeframe?.EventDesc?.text == null)
                return null;
            if (this.trend?.Timeframe?.EventDesc?.type === 1)
                return "<span style='margin-left:10px;padding-left:5px;padding-right:5px;color:white;background-color:" + utils.getColorForEvent(this.trend.Timeframe?.EventDesc) + ";'>" + (this.trend.Timeframe?.EventDesc?.text || '') + "</span> (" + (this.trend.Timeframe?.EventDesc?.md + ")" || '');
            return "Ereignis: " + (this.trend.Timeframe?.EventDesc?.text || '');
        }
    }
}
</script>